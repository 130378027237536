import React, {useEffect} from 'react';
import pym from "pym.js";

function Embed({children}) {

  useEffect(() => {
    const pymChild = new pym.Child({polling: 500});
    console.log(pymChild);
  }, []);

  return (
    <>{children}</>
  );
}

export default Embed;
