import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import DownloadIcon from "@mui/icons-material/Download";
import * as htmlToImage from "html-to-image";
import download from "downloadjs";

const OrangeButton = styled(Button)(() => ({
  width: 210,
}));

export default function StyledButton({
  text,
  icon,
  page,
  type,
}) {
  function handleOnClick() {
    if (type !== "data") {
      let node = document.getElementById("data-png");
      const filter = (node) => {
        const exclusionClasses = ["remove-from-download"];
        return !exclusionClasses.some((classname) => {
          const classesCopy = node && node.classList ? [...node.classList] : [];
          return classesCopy && classesCopy.includes(classname);
        });
      };
      let downloadName = page === "border" ? "border" : "scdi";
      htmlToImage.toPng(node, { filter: filter }).then(function (dataUrl) {
        download(dataUrl, `${downloadName}.png`);
      });
    }
  }

  return (
    <OrangeButton
      sx={{ mr: 2, mt: 2 }}
      onClick={() => handleOnClick()}
    >
      {text}
      {icon && <DownloadIcon />}
    </OrangeButton>
  );
}
