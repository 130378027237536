import React from "react";
import {createRoot} from 'react-dom/client';
import "./index.css";
import App from "./App";
import Embed from "./pages/embed";
import Region from "./pages/region";
import Border from "./pages/border";
import reportWebVitals from "./reportWebVitals";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Embed>
      <HashRouter>
        <Routes>
          <Route exact path="/" element={<Navigate replace to="/en/home" />}/>
          <Route path="/:lang/home" element={<App />} />
          <Route path="/:lang/region" element={<Region />} />
          <Route path="/:lang/border" element={<Border />} />
        </Routes>
      </HashRouter>
    </Embed>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
