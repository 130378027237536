import * as React from "react";
import Selector from "./Selector";
import YearSelector from "./YearSelector";
import Button from "@mui/material/Button";
import PlayButtonBorder from "./PlayButtonBorder";
import PlayButton from "./PlayButton";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  clearButton: {
    background: "transparent !important",
    color: "#E39659 !important",
    width:'max-content !important'
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function ControlPanel({
  year,
  min,
  max,
  setYear,
  optionsSelector,
  funcSelector,
  valueSelector,
  filter,
  setFilter,
  setPlay,
  play,
  date,
  setDate,
  setAccumulatedEvents,
  accumulatedEvents,
  month,
  setMonth,
  applyButton,
  page,
  setShownDate,
  shownDate,
  setFloatingDateOpacity,
  lng
}) {
  const { t } = useTranslation();
  const classes = useStyles();
    return (
    <Grid container spacing={2} sx={{pb:3,pt: 1}}>
      <Grid item xs={12} sm={9} md={page==='region' && lng==='fr'? 4:"auto"}>
        <Selector
          options={optionsSelector}
          func={funcSelector}
          value={valueSelector}
          applyButton={applyButton}
          setFilter={setFilter}
          setPlay={setPlay}
          setYear={setYear}
          MinYear={min}
          page={page}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={5}>
        <YearSelector
          play={play}
          year={year}
          min={min}
          max={max}
          func={setYear}
          setFilter={setFilter}
          shownDate={shownDate}
          page={page}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={"auto"}>
        {page === "border" ? (
          <PlayButtonBorder
            year={year}
            setYear={setYear}
            play={play}
            date={date}
            setDate={setDate}
            setPlay={setPlay}
            setAccumulatedEvents={setAccumulatedEvents}
            accumulatedEvents={accumulatedEvents}
            maxYear={max}
            minYear={min}
            setShownDate={setShownDate}
            shownDate={shownDate}
            setFloatingDateOpacity={setFloatingDateOpacity}
          />
        ) : (
          <PlayButton
            setPlay={setPlay}
            year={year}
            play={play}
            minYear={min}
            maxYear={max}
            setYear={setYear}
            filter={filter}
            month={month}
            setMonth={setMonth}
          />
        )}
        <Button
          className={classes.clearButton}
          style={{
            visibility:
              filter && filter.region.length > 0 ? "visible" : "hidden",
          }}
          onClick={() => {
            return (
              setFilter((filter) => ({ ...filter, year: year, region: "" })),
              setYear(year),
              funcSelector(""),
              setPlay(false),
              page==='border' && setFloatingDateOpacity(0),
              page==='border' && setDate(moment(`${year}-01`, "YYYY/MM")),
              page==='border' && setShownDate(`${year}/01`)
            )
          }}
        >
          {t('buttons.clearFilter')}
        </Button>
      </Grid>
    </Grid>

  );
}
