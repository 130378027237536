import "../App.css";
import {
  getCountriesJson,
  getGeometrySCDBIDBGeoJson,
  getUNPIVOT_SCDIDB_Json,
  transformDataForGraphs,
  transformCountryDataForGraphs,
  filterByType,
  getID_SCDI_Json,
} from "../utils/csv";
import React, { useState, useEffect, useRef } from "react";
import MapView from "../components/Map";
import ControlPanel from "../components/ControlPanel";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import StyledButton from "../components/StyledButton";
import theme from "../utils/theme";
import { ThemeProvider } from "@mui/material/styles";

import {
  Grid,
  ListItemText,
  List,
  ListItemIcon,
  Container,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SquareIcon from "@mui/icons-material/Square";
import ListItem from "@mui/material/ListItem";
import ReactECharts from "echarts-for-react";
import { useTranslation } from "react-i18next";
import PlayButton from "../components/PlayButton";

const useStyles = makeStyles((theme) => ({
  bigContainer: {
    padding: "10px 20px",
    background: "#FCFDF3",
  },
  container: {
    width: "100%",
    height: "100%",
    fontFamily: "Libre Franklin, sans-serif",
    boxSizing: "border-box",
    background: "#FCFDF3",
  },
  chartContainer: {
    display: "flex !important",
    "& .echarts-for-react": {
      width: "50%",
    },
  },
  title: {
    margin: 0,
  },
  chartCard: {
    "& .echarts-for-react": {
      width: "100%",
    },
  },
  chartTitle: {
    textTransform: "none",
    color: "#35536C",
    fontSize: 20,
    margin: "24px 0 10px 0",
  },
  chartTitleHighlight: {
    textTransform: "none",
    color: "#E39659",
    fontSize: 20,
    margin: "24px 0 10px 0",
  },
  chartSources: {
    color: "#35536C",
    fontSize: 12,
    paddingLeft: 5,
    marginTop: 0,
  },
}));

const parseHTML = require("html-react-parser");

function Region(props) {
  var ref = useRef(null);
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState(2024);
  const [month, setMonth] = useState("01");
  const [minYear, setMinYear] = useState(null);
  const [maxYear, setMaxYear] = useState(null);
  const [years, setYears] = useState([]);
  const [geometry, setGeometry] = useState(null);
  const [countriesGraphData, setCountriesGraphData] = useState([]);
  const [fullGraphData, setFullGraphData] = useState([]);
  const [region, setRegion] = useState("");
  const [UNPIVOT_SCDIBD, setUNPIVOT_SCDIBD] = useState(null);
  const [countries, setCountries] = useState(null);
  const [optionsSelector, setOptionsSelector] = useState([]);
  const [play, setPlay] = useState(false);
  const [filter, setFilter] = useState({ year: year, region: "" });
  const [ID_SCDI, setID_SCDI] = useState();
  const lng =
    window.location.hash !== "" ? window.location.hash.split("/")[1] : "en";
  const { t } = useTranslation();
  const [selectedGraphData, setSelectedGraphData] = useState(null);
  const classes = useStyles();

  const [viewport, setViewport] = useState({
    longitude: 1.2778,
    latitude: 12.3512,
    zoom: 3.5,
  });

  const typeConflicts = [
    ["CH", "#225C72"],
    ["DH", "#C36628"],
    ["CL", "#97BD99"],
    ["DL", "#EFB78F"],
  ];

  const dataLayerGeometry = {
    id: "geometry",
    filter: ["has", "y" + year],
    type: "fill",
    paint: {
      "fill-color": {
        property: "y" + year,
        type: "categorical",
        stops: typeConflicts,
      },
      "fill-opacity": [
        "case",
        [
          "any",
          ["in", filter.region, ["get", "country_id"]],
          ["==", filter.region, ["get", "study_area"]],
        ],
        0.8,
        0.3,
      ],
    },
  };

  const getYears = () => {
    if (UNPIVOT_SCDIBD) {
      setYears([...new Set(UNPIVOT_SCDIBD.map((item) => item.year))]);
    }
  };

  useEffect(() => {
    if (!loading) {
      setOptionsSelector(() => {
        let regionArray = countries.filter(
          (country) =>
            country.country_id === "RCS" ||
            country.country_id === "RLC" ||
            country.country_id === "RL"
        );
        let countriesArray = countries.filter(
          (country) =>
            country.country_id !== "RCS" &&
            country.country_id !== "RLC" &&
            country.country_id !== "RL"
        );

        return regionArray.concat(countriesArray).map((country) => {
          let country_name;
          lng === "en"
            ? (country_name = country.country_name_en)
            : (country_name = country.country_name_fr);
          return { value: country.country_id, label: country_name };
        });
      });

      setMaxYear(Math.max(...years));
      setMinYear(Math.min(...years));
      setYear(Math.max(...years));
      setFilter((filter) => ({
        ...filter,
        year: Math.max(...years),
        region: "",
      }));
    }
  }, [loading]);

  useEffect(() => {
    const countriesWithConflict = countriesGraphData.map((c) => c.country);

    const filtered = optionsSelector.filter((a) =>
      countriesWithConflict.includes(a.value)
    );

    if (optionsSelector.length === filtered.length) return;

    setOptionsSelector(filtered);
  }, [optionsSelector, countriesGraphData]);
  const loadGraphData = () => {
    var graphData = fullGraphData;
    if (filter && filter.region) {
      graphData = countriesGraphData.filter(
        (country) => country.country === filter.region
      )[0].years;
    }

    const array = filterByType(graphData).map((a) => a.values);
    const total = array[0]
      .map((_, colIndex) =>
        array.map((row) => (row[colIndex] !== undefined ? row[colIndex] : 0))
      )
      .map((row) => row.reduce((a, b) => parseInt(a) + parseInt(b), 0));

    var lineData =
      graphData && graphData.length > 0
        ? filterByType(graphData).map((type, i) => {
            return {
              name: t(`region.type_${type.type}`),
              type: "line",
              smooth: true,
              data: type.values.map((a, j) => {
                return [type.years[j] + "/01/01", a];
              }),
              symbol: "circle",
              symbolSize: 5,
            };
          })
        : [];

    const markLine = filter
      ? {
          animation: false,
          silent: true,
          symbol: ["none", "none"],
          label: {
            formatter: (p) => p.data.value.split("/")[0],
            padding: [0, 0, 0, 40],
            fontWeight: 800,
            color: "#35536C",
          },
          data: [{ xAxis: year + "/" + (year < 2023 ? month : "01") + "/01" }],
          lineStyle: {
            color: "#35536C",
            shadowColor: "#35536C",
            shadowOffsetY: -18,
          },
        } 
      : {};

    if (lineData.length > 0) lineData[0].markLine = markLine;

    var accData =
      graphData && graphData.length > 0
        ? filterByType(graphData).map((type, index) => {
            return {
              name: t(`region.type_${type.type}`),
              type: "line",
              stack: "Total",
              smooth: true,
              areaStyle: {},
              emphasis: {
                focus: "series",
              },
              data: type.values.map((a, j) => {
                let percentage;

                if (isNaN(a / total[j])) percentage = 0;
                else percentage = a / total[j];
                return [
                  type.years[j] + "/01/01",
                  (percentage * 100).toFixed(2),
                ];
              }),
              symbol: "circle",
              symbolSize: 5,
            };
          })
        : [];

    if (accData.length > 0) accData[0].markLine = markLine;

    const option = {
      color: ["#225C72", "#97BD99", "#C36628", "#EFB78F"],
      grid: {
        top: window.innerWidth < 745 ? 125 : 75,
        bottom: 30,
        left: 36,
        //right: 70,
      },
      tooltip: {
        padding: 20,
        formatter: function (params) {
          return `${params.data[1]}`;
        },
        // function (params, ticket, callback) {
        //   return ID_SCDI[params.seriesIndex][
        //     lng === "en" ? "caption_en" : "caption_fr"
        //   ];
        // },
      },
      legend: {
        icon: "circle",
        top: 5,
        left: 0,
        width: window.innerWidth <= 768 ? "auto" : 400,
      },
      xAxis: {
        type: "time",
        max: 3600 * 1000 * 26 * 365 * 52,
      },
      yAxis: {
        axisLabel: { align: "left", padding: [0, 0, 0, -20] },
      },
      series: lineData,
      textStyle: {
        fontFamily: '"Libre Franklin", sans-serif',
        color: "#35536C",
      },
    };

    const optionAc = {
      color: ["#225C72", "#97BD99", "#C36628", "#EFB78F"],
      grid: {
        top: window.innerWidth < 745 ? 125 : 75,
        bottom: 35,
        left: 36,
      },
      tooltip: {
        padding: 20,
        formatter: function (params, ticket, callback) {
          return `${params.data[1]} %`;
          // return ID_SCDI[params.seriesIndex][
          //   lng === "en" ? "caption_en" : "caption_fr"
          // ];
        },
      },
      legend: {
        icon: "circle",
        top: 5,
        left: 0,
        textStyle: {
          lineHeight: 5,
        },
        width: window.innerWidth <= 768 ? "auto" : 400,
      },
      xAxis: {
        type: "time",
        max: 3600 * 1000 * 26 * 365 * 52,
      },
      yAxis: {
        max: 100,
        axisLabel: { align: "left", padding: [0, 0, 0, -28], formatter: '{value} %', fontSize: 10 },
      },
      series: accData,
      textStyle: {
        fontFamily: '"Libre Franklin", sans-serif',
        color: "#35536C",
        lineHeight: 1,
      },
    };

    setSelectedGraphData({ lines: option, stacked: optionAc });
  };

  useEffect(() => getYears(), [UNPIVOT_SCDIBD]);

  useEffect(() => loadGraphData(), [year, month]);
  useEffect(() => loadGraphData(), [filter]);

  useEffect(() => {
    async function getUNPIVOT_SCDIDB() {
      try {
        const parsedData = await getUNPIVOT_SCDIDB_Json();

        return setUNPIVOT_SCDIBD(parsedData);
      } catch (e) {
        console.log("ERROR in getServerSideProps index", e);
        return {
          props: {
            message: "Error. Try refreshing the page.",
          },
        };
      }
    }

    async function getCountries() {
      try {
        const parsedData = await getCountriesJson();
        return setCountries(parsedData);
      } catch (e) {
        console.log("ERROR in getServerSideProps index", e);
        return {
          props: {
            message: "Error. Try refreshing the page.",
          },
        };
      }
    }

    async function geID_SCDI() {
      try {
        const parsedData = await getID_SCDI_Json();
        return setID_SCDI(parsedData);
      } catch (e) {
        console.log("ERROR in getServerSideProps index", e);
        return {
          props: {
            message: "Error. Try refreshing the page.",
          },
        };
      }
    }

    async function getGeometrySCDBIDB() {
      try {
        const parsedData = await getGeometrySCDBIDBGeoJson();

        const graphDataCountries = transformCountryDataForGraphs(parsedData);

        const graphDataFull = transformDataForGraphs(parsedData);
        setCountriesGraphData(graphDataCountries);
        setFullGraphData(graphDataFull);

        return setGeometry(parsedData);
      } catch (e) {
        console.log("ERROR in getServerSideProps index", e);
        return {
          props: {
            message: "Error. Try refreshing the page.",
          },
        };
      }
    }

    Promise.all([
      getGeometrySCDBIDB(),
      getCountries(),
      getUNPIVOT_SCDIDB(),
      geID_SCDI(),
    ])
      .then((values) => {
        return values, setLoading(false);
      })
      .catch((reason) => {
        console.log(reason);
      });
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.bigContainer}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              padding: 5,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
          <Typography variant="h2" gutterBottom={true} sx={{mb:3}}>
                  {t("region.map.title")}</Typography>
            <ControlPanel
              optionsSelector={optionsSelector}
              funcSelector={setRegion}
              valueSelector={region}
              min={minYear}
              max={maxYear}
              setYear={setYear}
              year={year}
              region={region}
              filter={filter}
              setFilter={setFilter}
              setPlay={setPlay}
              play={play}
              month={month}
              setMonth={setMonth}
              page="region"
              lng={lng}
            />{" "}
            <div className={classes.container} id="data-png">
              {/* Map container */}
              <Container
                disableGutters={true}
                sx={{ overflow: "hidden", mt: 2 }}
                variant="border"
              >
                <MapView
                  viewport={viewport}
                  setViewport={setViewport}
                  data={[[geometry, dataLayerGeometry]]}
                  filterRegion={filter.region}
                  region={region}
                  countries={countries}
                  page="region"
                  year={year}
                  lng={lng}
                />
              </Container>
              {/* Map's references container */}
              <Container
                variant="border"
                sx={{
                  px: { xs: 1, sm: 8 },
                  py: 4,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Container disableGutters>
                  <Typography
                    variant="h3"
                    sx={{ fontSize: "1.25rem" }}
                    gutterBottom={true}
                  >
                    {t("region.map.references.title")}
                  </Typography>
                  <List
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                    {typeConflicts.map((conflict) => (
                      <ListItem key={`${conflict[0]}`}>
                        <ListItemIcon sx={{ pr: 1 }}>
                          <SquareIcon sx={{ color: conflict[1] }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            ID_SCDI.find(
                              (element) => element.id === conflict[0]
                            )[lng === "en" ? "name_en" : "name_fr"]
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                  <Typography
                    sx={{
                      pt: 2,
                      pb: 2,
                      color: "#927349",
                      fontSize: ".8rem",
                      fontWeight: 600,
                    }}
                  >
                    {parseHTML(t("mapDisclaimer"))}
                  </Typography>
                </Container>
              </Container>
              {/* Play button Container */}
              
              {/* Charts container */}
              <Grid
                className={classes.container}
                sx={{ borderTop: "1px solid #C9B99F" }}
              >
                {selectedGraphData && (
                  <Grid
                    container
                    spacing={2}
                    className={classes.chartContainer}
                    sx={{ px: 1 }}
                  >
                    <Grid item xs={12} sm={6} className={classes.chartCard}>
                      <h6 className={classes.chartTitle}>
                        {t("region.chartNumberConflicts.title")}{" "}
                        <span className={classes.chartTitleHighlight}>
                          {t("region.chartNumberConflicts.titleHighlight")}
                        </span>
                      </h6>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#2A2A35",
                          fontFamily: "Libre Franklin",
                          fontSize: 14,
                          fontWeight: 400,
                          mb: { xs: 2 },
                        }}
                      >
                        {t("region.chartNumberConflicts.description")}
                      </Typography>

                      <ReactECharts
                        ref={(e) => {
                          ref = e;
                        }}
                        option={selectedGraphData.lines}
                        notMerge={true}
                        lazyUpdate={true}
                        theme={"theme_name"}
                      />
                      <p className={classes.chartSources}>
                        {t("region.chartNumberConflicts.sources")}
                      </p>
                    </Grid>

                    <Grid item xs={12} sm={6} className={classes.chartCard}>
                      <h6 className={classes.chartTitle}>
                        {t("region.chartProportionConflicts.title")}{" "}
                        <span className={classes.chartTitleHighlight}>
                          {t("region.chartProportionConflicts.titleHighlight")}
                        </span>
                      </h6>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#2A2A35",
                          fontFamily: "Libre Franklin",
                          fontSize: 14,
                          fontWeight: 400,
                          mb: { xs: 2 },
                        }}
                      >
                        {t("region.chartProportionConflicts.description")}
                      </Typography>
                      <ReactECharts
                        option={selectedGraphData.stacked}
                        notMerge={true}
                        lazyUpdate={true}
                        theme={"theme_name"}
                      />
                      <p className={classes.chartSources}>
                        {t("region.chartProportionConflicts.sources")}
                      </p>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Box sx={{ mb: 3, position: "relative" }}>
                <Box
                  className="remove-from-download"
                  style={{
                    backgroundColor: "#FCFDF3",
                    zIndex: "99",
                    position: "sticky",
                  }}
                >
                  <p style={{ visibility: "hidden" }}>
                    {t("downloadDisclaimer")}
                  </p>
                </Box>

                <p
                  style={{
                    position: "absolute",
                    top: 0,
                    margin: 0,
                    zIndex: "9",
                  }}
                >
                  {t("downloadDisclaimer")}
                </p>
              </Box>
            </div>
            <div className={classes.container}>
              {/* Dinamic text container             */}
              <StyledButton text={t("buttons.downloadImage")} icon={true} />
            </div>
          </>
        )}
      </div>
    </ThemeProvider>
  );
}

export default Region;
