import * as React from "react";
import {useEffect} from 'react'
import { Box } from "@mui/system";
import { Slider } from "@mui/material";
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  slider: {
    color: "#E39659",
    height: 2,
    padding: "15px 0",

    "& .MuiSlider-track": {
      height: 2,
      color: "#E39659",
    },
    "& .MuiSlider-valueLabel": {
      fontSize: 12,
      fontWeight: 700,
      top: -6,
      backgroundColor: "unset",
      color: "black",
      "&:before": {
        display: "none",
      },
      "& *": {
        background: "transparent",
        color: "black",
      },
    },

    "& .MuiSlider-thumb": {
      height: 24,
      width: 24,
      backgroundColor: "#E39659",
      border: "2px solid #E39659",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
  },
  box: {
    display: "flex",
    alignItems:'center',
  },
  label:{
    fontFamily: "Libre Franklin, sans-serif",
    fontSize: 14,
    transform: "translateY(-5px)",
    color: "#35536C"
  }
}));

export default function YearSelector({
  year,
  min,
  max,
  func,
  setFilter,
  play
}) {
  const classes = useStyles();
    
  return (
    <div className={classes.controlPanel}>
      <Box className={classes.box}sx={{pt:{xs:4,sm:0}}}>
        <p className={classes.label} style={{ marginRight: 24 }}>{min}</p>
        <Slider
          aria-label="Select a year"
          defaultValue={max}
          valueLabelDisplay="on"
          step={1}
          min={min}
          max={max}
          value={year}
          disabled={play ? true : false}
          onChange={(event, newValue) => {
            setFilter((filter) => ({
              ...filter,
              year: newValue,
            }));
            func(newValue);
          }}
          className={classes.slider}
        />

        <p className={classes.label} style={{ marginLeft: 24 }}>{max}</p>
      </Box>
    </div>
  );
}
