import { createTheme, ThemeProvider } from "@mui/material/styles";

let theme = createTheme({});

theme = createTheme({
  typography: {
    fontFamily: "'Libre Franklin', sans-serif",
  },
  palette:{
    primary: {
      main: '#E39659'
    }
  },
  components: {
    MuiListItemIcon: {
      defaultProps: {
      },
      styleOverrides: {
        root: {
          minWidth: "min-content",
          justifyContent:'center',
        },
      },
    },
    MuiListItem: {
      defaultProps: {
        disablePadding: true,
      },
    },
    MuiContainer: {
      styleOverrides: {
        boxSizing: "border-box",
        root: { [theme.breakpoints.up("lg")]: { maxWidth: "none" } },
      },
      variants: [
        {
          props: { variant: "border" },
          style: {
            border: "1px solid #C9B99F",
            borderRadius: "4px"
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          fontWeight: 600,
          backgroundColor: "#E39659",
          border: 'solid 1px transparent',
          "&::hover": {
            backgroundColor: "#C36628",
          },
          "&:hover, &:active": {
            backgroundColor: "#C36628",
          },
          "&:focus":{
            border: "solid 1px #E39659",
            backgroundColor: "transparent",
            color:'#E39659'
          },
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#C9B99F",
          "&.Mui-checked": {
            color: "#C9B99F",
          }
        },
      },
    },
    MuiFormControlLabel:{
      styleOverrides: {
        root: {
          color: "#35536C",}}
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          whiteSpace: "pre-line",
        },
        h2: {
          fontWeight: 700,
          textTransform: "none",
          fontSize: "2rem",
          color: "#35536C",
        },
        h3: {
          fontWeight: 700,
          textTransform: "none",
          fontSize: "1.5rem",
          color: "#35536C",
        },
      },
    },
    MuiListSubheader:{
      styleOverrides: {
        root: {
          fontWeight: "700",
        color:'#35536C',
      fontStyle:'italic'}} 
    },
    MuiSlider	:{
      styleOverrides:{
        thumb:{
          " &:hover, &:active, &:focus,&.Mui-focusVisible": {
            backgroundColor: "#C36628",
            borderColor:"#C36628",
            boxShadow:' 0px 0px 0px 4px rgba(227, 150, 89, 0.5) !important'
          },
        },
        
      }
    },
    MuiFormGroup:{
      styleOverrides:{
        root:{
          display:'inline-block'
        }
      }
    }
  },
});

export default theme;
