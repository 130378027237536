import React, { useState, useCallback, useRef, useEffect } from "react";
import Map, {
  ScaleControl,
  FullscreenControl,
  NavigationControl,
  Source,
  Layer,
  Popup,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import bbox from "@turf/bbox";
import { lineString } from "@turf/turf";
import { List, ListItem } from "@mui/material";
import mapConfig from '../utils/map-style-basic-v8-labels.json'
import { useTranslation } from "react-i18next";

const countryLabelStyle = mapConfig.country;
const cityLabelStyle = mapConfig.city;
const placeLabelStyle = mapConfig.place;

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

function getMapConfig (config, lng) {
  config.layout[`text-field`]=
  [
    "match",["get", "name_en"],
    "Ivory Coast","Côte d’Ivoire","Turkey","Türkiye",
    "Cape Verde","Cabo Verde",
    ["get",  `name_${lng}`]
  ] 
  return config
}

export default function MapView({
  viewport,
  setViewport,
  data,
  filterRegion,
  region,
  countries,
  page,
  year,
  lng,
  eventTypes
}) {
  const [hoverInfo, setHoverInfo] = useState(null);
  const mapRef = useRef(null);
    const yearProperty = "y" + year;
  const defaultRegionBounds=[
    [30, 0],
    [-10, 45],
  ] 
  const defaultBorderBounds=[
    [10,5],
    [-8, 28],
  ] 
  const defaultBounds=(page==='region')?defaultRegionBounds:defaultBorderBounds

  const { t } = useTranslation();
 
  useEffect(() => {
    if (filterRegion !== "" ) {
      let country = countries.find((element) => element.country_id === region);
      let array = country.bbox.replace("(", "").replace(")", "").split(",");
      const point_1 = [array[0], array[1]];
      const point_2 = [array[2], array[3]];
      const line = lineString([point_1, point_2]);
      const [minLng, minLat, maxLng, maxLat] = bbox(line);
      mapRef.current.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: 40, duration: 1000 }
      );
    } else if (mapRef.current !== null) {
      mapRef.current.fitBounds(
        defaultBounds,
        { padding: 40, duration: 1000 }
      );
    }
  }, [filterRegion]);
  const onHover = useCallback((event) => {
    
    const {
      features,
      point: { x, y },
    } = event;

    const hoveredFeature = features && features[0];

    // prettier-ignore
    setHoverInfo(hoveredFeature && {feature: hoveredFeature, x, y});
  }, []);

  useEffect(() => {
    if (mapRef.current !== null) {
      mapRef.current.fitBounds(
        defaultBounds,
        { padding: 40, duration: 1000 }
      );
    }
  }, []);

  function showCountryTooltip() {
    let countriesHover = [];
    let countriesIDHover = hoverInfo.feature.properties.country_id.split(";");
    countriesIDHover.map((country) =>
      countriesHover.push(
        countries.find((element) => element.country_id === country)[
          lng === "en" ? "country_name_en" : "country_name_fr"
        ]
      )
    );
    return countriesHover.toString();
  }
  return (
    <Map
    style={{ width: "100%", height: window.innerWidth<=500?'300px':'500px'}}
      bounds={defaultBounds}
      mapboxAccessToken={
        "pk.eyJ1IjoibWttZCIsImEiOiJjajBqYjJpY2owMDE0Mndsbml0d2V1ZXczIn0.el8wQmA-TSJp2ggX8fJ1rA"
      }
      ref={mapRef}
      initialViewState={viewport}
      onViewportChange={(newViewport) => setViewport(newViewport)}
      mapStyle="mapbox://styles/mkmd/ck9eg99c72gwg1imtdbugc9yn"
      onMouseMove={onHover}
      onMouseLeave={() =>{ setHoverInfo(null)}}
      scrollZoom={false}
      interactiveLayerIds={
        page === "border" ? ["borderViolence"] : ["geometry"]
      }
      preserveDrawingBuffer={true}
    >
      {data &&
        data.map((data, index) => (
          <div key={`source-${data[1].id}`}>
            <Source type="geojson" data={data[0]}>
              <Layer {...data[1]} />
            </Source>
            {hoverInfo && (
              <Popup
                anchor="top"
                longitude={
                  page === "region"
                    ? hoverInfo.feature.geometry.coordinates[0][0][0]
                    : hoverInfo.feature.geometry.coordinates[0]
                }
                latitude={
                  page === "region"
                    ? hoverInfo.feature.geometry.coordinates[0][0][1]
                    : hoverInfo.feature.geometry.coordinates[1]
                }
                onCloseButton={false}
                className="pop-up"
              >
                <List>
                  {page === "border" ? (
                    <>
                      <ListItem>
                        {t('border.map.tooltip.eventType')}: {eventTypes.find(event=>event.id===hoverInfo.feature.properties.event_type)[lng==='en'?'name_en':'name_fr'] }
                      </ListItem>
                      <ListItem>
                      {t('border.map.tooltip.eventDate')}: {hoverInfo.feature.properties.event_date}
                      </ListItem>
                      <ListItem>
                      {t('border.map.tooltip.fatalities')}: {hoverInfo.feature.properties.fatalities}
                      </ListItem>
                    </>
                  ) : (
                    <>
                      <ListItem>{t('region.map.tooltip.country')}: {showCountryTooltip()}</ListItem>
                      <ListItem>
                        SCDI: {t(`region.type_${hoverInfo.feature.properties[yearProperty]}`)}
                      </ListItem>
                      
                    </>
                  )}
                </List>
              </Popup>
            )}
          </div>
        ))}
       
      <FullscreenControl position="top-left" />
      <NavigationControl
        position="top-left"
        showCompass={false}
        showZoom="true"
      />
      <ScaleControl />
      <Source type='vector' id='mapbox' url="mapbox://mapbox.mapbox-streets-v7">
      <Layer {...getMapConfig(countryLabelStyle,lng)}/>
      <Layer {...getMapConfig(placeLabelStyle,lng)}/>
      <Layer {...getMapConfig(cityLabelStyle,lng)}/>
      </Source>
    </Map>
  );
}
