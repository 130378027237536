import "./App.css";
import React from 'react'
import { Link } from "react-router-dom";
import './i18n';


const langsTitles = {"en": "🇺🇸 English", "fr":"🇫🇷 French"};
const viewsTitles = {"region": "🌍 Region view", "border":"🌐 Border View"};

function App() {
  return (
    <div className="App">
      <h1>OECD SWAC test page</h1>
      <hr/>
      {Object.keys(viewsTitles).map(view =>
        <div key={view}>
          <h2>{viewsTitles[view]}</h2>
          {Object.keys(langsTitles).map(lang =>
            <p key={lang}><Link target="_blank" to={`/${lang}/${view}`}>{langsTitles[lang]} Version</Link><br/></p>
          )}
          <hr/>
        </div>
      )}
    </div>
  );
}

export default App;
