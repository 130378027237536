import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ListSubheader from "@mui/material/ListSubheader";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  selector: {
    //width: '25%',
    minWidth: "300px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export default function Selector({
  options,
  func,
  value,
  applyButton,
  setFilter,
  setPlay,
  setYear,
  MinYear,
  page,
}) {
  // Remove Spain, Moroco, Egypt, Sudan, Togo and Central African Republic from the list
  options = options.filter(
    (option) =>
      option.value !== "ESP" &&
      option.value !== "MAR" &&
      option.value !== "EGY" &&
      option.value !== "SDN" &&
      option.value !== "TGO" &&
      option.value !== "CAF"
  );
  const { t } = useTranslation();

  const handleChange = (event) => {
    func(event.target.value);
    if (!applyButton)
      return (
        setPlay(false),
        setFilter((filter) => ({ ...filter, region: event.target.value, year: MinYear })),
        setYear(MinYear),
        setTimeout(() => {setPlay(true)}, 50)
        // setPlay(true)
      );
    else func(event.target.value);
  };
  const classes = useStyles();
  return (
    <Box className={classes.selector} sx={{ mx: 1 }}>
      <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label">
          {page === "region"
            ? t("region.selectorButton.placeholder")
            : t("border.selectorButton")}
        </InputLabel>

        {page === "region" ? (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            label={t("region.selectorButton.placeholder")}
            onChange={handleChange}
            MenuProps={{
              PaperProps: {
                sx: {
                  "& .MuiMenuItem-root": {
                    padding: 2,
                  },
                },
              },
            }}
          >
            <ListSubheader>
              {t("region.selectorButton.subgroup_areas")}
            </ListSubheader>
            {options.slice(0, 3).map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            <ListSubheader>
              {t("region.selectorButton.subgroup_countries")}
            </ListSubheader>
            {options.slice(3, -1).map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            label={t("button.selectorButton")}
            onChange={handleChange}
            MenuProps={{
              PaperProps: {
                sx: {
                  "& .MuiMenuItem-root": {
                    padding: 2,
                  },
                },
              },
            }}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
    </Box>
  );
}
