const axios = require('axios')

export async function countriesCSV() {
    return await axios.get("https://docs.google.com/spreadsheets/d/e/2PACX-1vQpYYcbw5O0wUiAYCO20GNKzwbjSgzrimVgyhXSgFDXgNPjACrkwuWJuaeOaDDYQpRo8rPouSjONNNr/pub?gid=1816585807&single=true&output=csv");
}

export async function selectedBorderViolenceCSV (){
    return await axios.get("https://docs.google.com/spreadsheets/d/e/2PACX-1vQpYYcbw5O0wUiAYCO20GNKzwbjSgzrimVgyhXSgFDXgNPjACrkwuWJuaeOaDDYQpRo8rPouSjONNNr/pub?gid=689933070&single=true&output=csv")
}


export async function geometrySCDBIDB_CSV() {
    return await axios.get("https://docs.google.com/spreadsheets/d/e/2PACX-1vQpYYcbw5O0wUiAYCO20GNKzwbjSgzrimVgyhXSgFDXgNPjACrkwuWJuaeOaDDYQpRo8rPouSjONNNr/pub?gid=26848819&single=true&output=csv")

}

export async function comparativeCSV() {
    return await axios.get("https://docs.google.com/spreadsheets/d/e/2PACX-1vQpYYcbw5O0wUiAYCO20GNKzwbjSgzrimVgyhXSgFDXgNPjACrkwuWJuaeOaDDYQpRo8rPouSjONNNr/pub?gid=1882083370&single=true&output=csv")
}

export async function UNPIVOT_SCDIDB_CSV() {
    return await axios.get("https://docs.google.com/spreadsheets/d/e/2PACX-1vQpYYcbw5O0wUiAYCO20GNKzwbjSgzrimVgyhXSgFDXgNPjACrkwuWJuaeOaDDYQpRo8rPouSjONNNr/pub?gid=1917640101&single=true&output=csv")
}

export async function ID_Event_Type_CSV () {
    return await axios.get("https://docs.google.com/spreadsheets/d/e/2PACX-1vQpYYcbw5O0wUiAYCO20GNKzwbjSgzrimVgyhXSgFDXgNPjACrkwuWJuaeOaDDYQpRo8rPouSjONNNr/pub?gid=1537986390&single=true&output=csv")
}

export async function ID_SCDI_CSV () {
    return await axios.get("https://docs.google.com/spreadsheets/d/e/2PACX-1vQpYYcbw5O0wUiAYCO20GNKzwbjSgzrimVgyhXSgFDXgNPjACrkwuWJuaeOaDDYQpRo8rPouSjONNNr/pub?gid=606391377&single=true&output=csv")
}
