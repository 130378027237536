import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';


import en from "./locales/en.json" 
import fr from "./locales/fr.json"

const lng = window.location.hash!==''? window.location.hash.split('/')[1] : "en";
const resources = {en,fr};

i18n
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .use(LanguageDetector)
  .init({
    resources,
    lng: lng,
    fallbackLng: "en",  
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ['path', 'navigator']
    },

  
  });
  
document.documentElement.lang = i18n.language  // <---- this line right here
export default i18n;